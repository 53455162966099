html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::selection {
  background: #b3d4fc;
  color: #fff;
  text-shadow: none;
}

.fonts-loaded body {
  font-family: 'Roboto', sans-serif;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

@viewport {
  width: device-width;
}
