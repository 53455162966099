h1, h3, h4, footer, header {
  text-align: center;
  color: #fff;
  font-weight: 700;
}

header {
  padding: 4rem 0 1rem;
  & svg {
    width: 50%;
    margin-bottom: 2rem;
  }
  & h1 {
    margin-top: 3rem;
    background-color: transparent;
    background-color: #2b3954;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}

span {
  font-weight: 300;
}

h1 {
  background-color: #2b3954;
  padding: 1rem 0;
  font-size: 2.6rem;
  margin-bottom: 0;
}

h3 {
  font-size: 1.8rem;
}
a {
  color: #fff;
  font-size: 4rem;
  &:hover {
    color:#2b3954;
    overflow: hidden;
    text-shadow: 3px 3px 0 #fff , 
    4px 4px 0 #fff , 
    5px 5px 0 #fff , 
    6px 6px 0 #fff , 
    7px 7px 0 #fff , 
    8px 8px 0 #fff , 
    9px 9px 0 #fff , 
    10px 10px 0 #fff , 
    11px 11px 0 #fff , 
    12px 12px 0 #fff , 
    13px 13px 0 #fff , 
    14px 14px 0 #fff , 
    15px 15px 0 #fff , 
    16px 16px 0 #fff , 
    17px 17px 0 #fff , 
    18px 18px 0 #fff , 
    19px 19px 0 #fff , 
    20px 20px 0 #fff , 
    21px 21px 0 #fff , 
    22px 22px 0 #fff , 
    23px 23px 0 #fff , 
    24px 24px 0 #fff , 
    25px 25px 0 #fff , 
    26px 26px 0 #fff , 
    27px 27px 0 #fff , 
    28px 28px 0 #fff , 
    29px 29px 0 #fff , 
    30px 30px 0 #fff ,
    31px 31px 0 #fff , 
    32px 32px 0 #fff , 
    33px 33px 0 #fff , 
    34px 34px 0 #fff , 
    35px 35px 0 #fff , 
    36px 36px 0 #fff , 
    37px 37px 0 #fff , 
    38px 38px 0 #fff , 
    39px 39px 0 #fff , 
    40px 40px 0 #fff ,
    41px 41px 0 #fff , 
    42px 42px 0 #fff , 
    43px 43px 0 #fff , 
    44px 44px 0 #fff , 
    45px 45px 0 #fff , 
    46px 46px 0 #fff , 
    47px 47px 0 #fff , 
    48px 48px 0 #fff , 
    49px 49px 0 #fff , 
    50px 50px 0 #fff ,
    51px 51px 0 #fff , 
    52px 52px 0 #fff , 
    53px 53px 0 #fff , 
    54px 54px 0 #fff , 
    55px 55px 0 #fff , 
    56px 56px 0 #fff , 
    57px 57px 0 #fff , 
    58px 58px 0 #fff , 
    59px 59px 0 #fff , 
    60px 60px 0 #fff ,
    61px 61px 0 #fff , 
    62px 62px 0 #fff , 
    63px 63px 0 #fff , 
    64px 64px 0 #fff , 
    65px 65px 0 #fff , 
    66px 66px 0 #fff , 
    67px 67px 0 #fff , 
    68px 68px 0 #fff , 
    69px 69px 0 #fff , 
    70px 70px 0 #fff ,
    71px 71px 0 #fff , 
    72px 72px 0 #fff , 
    73px 73px 0 #fff , 
    74px 74px 0 #fff , 
    75px 75px 0 #fff , 
    76px 76px 0 #fff , 
    77px 77px 0 #fff , 
    78px 78px 0 #fff , 
    79px 79px 0 #fff , 
    80px 80px 0 #fff ,
    81px 81px 0 #fff , 
    82px 82px 0 #fff , 
    83px 83px 0 #fff , 
    84px 84px 0 #fff , 
    85px 85px 0 #fff , 
    86px 86px 0 #fff , 
    87px 87px 0 #fff , 
    88px 88px 0 #fff , 
    89px 89px 0 #fff , 
    90px 90px 0 #fff ,
    91px 91px 0 #fff , 
    92px 92px 0 #fff , 
    93px 93px 0 #fff , 
    94px 94px 0 #fff , 
    95px 95px 0 #fff , 
    96px 96px 0 #fff , 
    97px 97px 0 #fff , 
    98px 98px 0 #fff , 
    99px 99px 0 #fff , 
    100px 100px 0 #fff ,
    101px 101px 0 #fff , 
    102px 102px 0 #fff , 
    103px 103px 0 #fff , 
    104px 104px 0 #fff , 
    105px 105px 0 #fff , 
    106px 106px 0 #fff , 
    107px 107px 0 #fff , 
    108px 108px 0 #fff , 
    109px 109px 0 #fff , 
    110px 110px 0 #fff ,
    111px 111px 0 #fff , 
    112px 112px 0 #fff , 
    113px 113px 0 #fff , 
    114px 114px 0 #fff , 
    115px 115px 0 #fff , 
    116px 116px 0 #fff , 
    117px 117px 0 #fff , 
    118px 118px 0 #fff , 
    119px 119px 0 #fff , 
    120px 120px 0 #fff ;
  }
}

footer {
  margin-top: 6rem;
  padding: 1rem 0;
  background-color: #2b3954;
  & a {
    font-size: 2rem;
    /*display: block;*/
  }
  & .icon-email {
    margin-right: 1.4 rem;
  }
}

input, textarea {
  color: #2b3954;
}
textarea.materialize-textarea {
  min-height: 5.4rem;
}

#map-canvas {
  height: 30rem;
  border-bottom: 1.4rem solid #2b3954;
}
h2 {
  color: #2b3954;
  text-align: center;
}
h4 {
  color: #2b3954;
  font-size: 1.6rem;
}



/* Materialize */
input:not([type]):focus:not([readonly]), input[type=text]:focus:not([readonly]), input[type=password]:focus:not([readonly]), input[type=email]:focus:not([readonly]), input[type=url]:focus:not([readonly]), input[type=time]:focus:not([readonly]), input[type=date]:focus:not([readonly]), input[type=datetime]:focus:not([readonly]), input[type=datetime-local]:focus:not([readonly]), input[type=tel]:focus:not([readonly]), input[type=number]:focus:not([readonly]), input[type=search]:focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #2b3954;
    box-shadow: 0 1px 0 0 #2b3954;
}
input:not([type]):focus:not([readonly])+label, input[type=text]:focus:not([readonly])+label, input[type=password]:focus:not([readonly])+label, input[type=email]:focus:not([readonly])+label, input[type=url]:focus:not([readonly])+label, input[type=time]:focus:not([readonly])+label, input[type=date]:focus:not([readonly])+label, input[type=datetime]:focus:not([readonly])+label, input[type=datetime-local]:focus:not([readonly])+label, input[type=tel]:focus:not([readonly])+label, input[type=number]:focus:not([readonly])+label, input[type=search]:focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label {
  color: #2b3954;
}
button:focus {
  background-color: #2b3954;
}

input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea.materialize-textarea {
  border-bottom: 1px solid #2b3954;
}
.input-field label {
  color: #2b3954;
  font-size: 1.2rem;
}

.btn, .btn-large {
  background-color: #2b3954;
  &:before {
    margin-right:1rem;
  }
}

.btn:hover, .btn-large:hover {
  background-color: #2b3954;
}






/* CONTACT */
#msg{
  height: 64px;
  color: #2b3954;
}
#msg-all{
  display: none;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 20px;
  color: red;
}
.backGload{
  background: url('../media/loading.gif') no-repeat center;
  margin: 2rem 0;
}
.contact-error {
  -webkit-box-shadow: inset 0 0 2px red !important;
  -moz-box-shadow: inset 0 0 2px red !important;
  box-shadow: inset 0 0 2px red !important;
}